import React, { forwardRef } from "react"
import PropTypes from "prop-types"

import { cls } from "../../utils/helpers"

const classes = {
  base: 'rounded font-semibold focus:outline-none transition ease-in-out duration-300',
  disabled: 'opacity-50 cursor-not-allowed',
  size: {
    small: 'px-2 py-1 text-sm',
    normal: 'px-4 py-2',
    large: 'px-5 py-3'
  },
  variant: {
    'primary-light': 'bg-primary-100 hover:bg-primary-200 focus:ring-2 focus:ring-primary-300 focus:ring-opacity-50 text-primary-500',
    'primary': 'bg-primary-500 hover:bg-primary-600 focus:ring-2 focus:ring-primary-500 focus:ring-opacity-50 text-white',
    'primary-dark': 'bg-primary-700 hover:bg-primary-800 focus:ring-2 focus:ring-primary-500 focus:ring-opacity-50 text-white',
    'danger': 'bg-red-500 hover:bg-red-800 focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 text-white'
  }
}

const Button = forwardRef(
  (
    {
      children,
      type = 'button',
      className,
      variant = 'primary',
      size = 'normal',
      disabled = false,
      ...props
    }, ref
  ) => (
    <button
      ref={ref}
      disabled={disabled}
      type={type}
      className={cls(`
        ${classes.base}
        ${classes.size[size]}
        ${classes.variant[variant]}
        ${disabled && classes.disabled}
        ${className}
      `)}
      {...props}
    >
      {children}
    </button>
  ));

Button.propTypes = {
  children: PropTypes.node.isRequired,
  submit: PropTypes.oneOf(['submit', 'button']),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf([
    'primary-light',
    'primary',
    'primary-dark',
    'secondary',
    'danger',
  ]),
  size: PropTypes.oneOf([
    'small',
    'normal',
    'large',
  ])
}

export default Button
