import * as React from "react"
import { Link } from "gatsby"

import logo from "../images/boundary-logo.svg"
import Register from "../components/ui/register"

const RegisterPage = () => {
  return (
    <div className="min-h-screen bg-primary-900 text-white">
      <main className="max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex-shrink-0 pt-16">
          <img
            className="mx-auto h-12 w-auto"
            src={logo}
            alt="Logo"
          />
        </div>

        <div className="max-w-xl mx-auto py-16 sm:py-24">
          <div className="text-center">
            <p className="text-base font-semibold">Coming soon</p>

            <h1 className="mt-2 text-4xl font-semibold tracking-tight sm:text-5xl sm:tracking-tight">
              Register for early access
            </h1>

            <p className="mt-2 text-lg text-primary-200">We're not live yet, but enter your email to be notified when we're ready!</p>
          </div>

          <div className="mt-12 text-center">
            <Register />

            <div className="mt-8">
              <Link to="/" className="text-base font-medium">
                Or go back home<span aria-hidden="true"> &rarr;</span>
              </Link>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export const Head = () => <>
  <title>Register - Boundary CMS</title>

  <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
  <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
  <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
  <link rel="manifest" href="/site.webmanifest" />
  <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#515BE7" />
  <meta name="msapplication-TileColor" content="#515BE7" />
  <meta name="theme-color" content="#ffffff" />
</>

export default RegisterPage
